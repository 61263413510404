/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import config from "../config/config";
import {
    PAGE_ABOUT_CONTENT_TYPE,
    PAGE_EVENTS,
    PAGE_GRANT,
    PAGE_INITIATIVES,
    PAGE_NEWS
} from "../constants";
import { makeRequest } from "../utils/http-requests";

/**
 * Contentful Service
 *
 * This service is now only a shell of the original ContentfulService, sending
 * all requests to the true ContentfulService on the proxy-server
 * (on the api-server).
 */
export class ContentfulService {
    cookiesJSON: any;
    /**
     * Constructor
     */
    constructor(cookiesJSON?) {
        this.cookiesJSON = cookiesJSON;
    }

    /**
     * Format an article object from Contentful
     * @param article
     * @returns {{image: *}}
     */
    static formatArticleObject = (article) => {
        let image = null;
        let document = null;
        let extraLinks = null;
        let author = null;

        if (article.author && Array.isArray(article.author)) {
            author = article.author.map((entry) => ({
                ...entry.fields,
                id: entry.sys.id
            }));
            delete article.author;
        } else {
            author = article.author;
        }

        if (
            article.document &&
            article.document.fields &&
            article.document.fields.file
        ) {
            document = article.document.fields.file.url;
            delete article.document;
        }

        if (article.logo && article.logo.fields && article.logo.fields.file) {
            image = article.logo.fields.file.url;
            delete article.logo;
        }
        if (
            article.image &&
            article.image.fields &&
            article.image.fields.file
        ) {
            image = article.image.fields.file.url;
            delete article.image;
        }
        if (
            article.overviewImage &&
            article.overviewImage.fields &&
            article.overviewImage.fields.file
        ) {
            image = article.overviewImage.fields.file.url;
            delete article.overviewImage;
        }

        if (article.related) {
            const links = article.related
                .filter((link) => link.fields)
                .map((link) => ({
                    contentType: link.sys.contentType.sys.id,
                    linkId: link.sys.id,
                    title: link.fields.title
                        ? link.fields.title
                        : link.fields.articleTitle,
                    mediaURL: link.fields.link ? link.fields.link : null
                }));

            if (links.length > 0) {
                extraLinks = links;
            }

            delete article.related;
        }

        return { ...article, image, document, extraLinks, author };
    };

    /**
     * Get home page stats
     * @returns {Promise<void>}
     */
    getPageInfo(page) {
        return this.getArticles(PAGE_ABOUT_CONTENT_TYPE, 0, 0, {
            "fields.page[in]": page
        }).then(({ articles }) => {
            let homeContent = {
                stats: {} as Record<string, string>,
                selectMembersContent: ""
            };
            articles.map((article) => {
                switch (article.page) {
                    case "Home: Capital Catalyzed": {
                        homeContent.stats.capital_catalyzed = article.body;
                        break;
                    }
                    case "Home: Expected Impact": {
                        homeContent.stats.expected_impact = article.body;
                        break;
                    }
                    case "Home: Member Institutions": {
                        homeContent.stats.member_institutions = article.body;
                        break;
                    }
                    case "Home: Member Users": {
                        homeContent.stats.member_users = article.body;
                        break;
                    }
                    case "Home: Deals Fundraising": {
                        homeContent.stats.deals_fundraising = article.body;
                        break;
                    }
                    case "Home: Funding Sought": {
                        homeContent.stats.funding_sought = article.body;
                        break;
                    }
                    case "Home: Select Members": {
                        homeContent.selectMembersContent = article.body;
                        break;
                    }
                    case "Home: Grants Awarded": {
                        homeContent.stats.grants_awarded = article.body;
                        break;
                    }
                    case "Home: Exclusive Publications": {
                        homeContent.stats.exclusive_publications = article.body;
                    }
                }
            });

            return homeContent;
        });
    }

    /**
     * Gets a list of news articles based on the limit.
     * @param contentType
     * @param limit
     * @param skip
     * @param options
     * @returns {Promise<{articles: Array, total: number}>}
     */
    async getArticles(
        contentType,
        limit,
        skip = 0,
        options: Record<string, string | number | boolean> = {
            order: "-fields.date"
        } // TS HACK Record
    ) {
        let articles = [];
        let total = 0;

        options = {
            content_type: contentType,
            ...options
        };

        if (skip && skip > 0) {
            options.skip = skip;
        }

        if (limit && limit > 0) {
            options.limit = limit;
        }

        try {
            let response = await this.getPageEntities(options);
            // TS CODE CHANGE
            if (response && response.total) {
                total = response.total;
            }

            if (response && response.items) {
                articles = response.items.map((article) =>
                    ContentfulService.formatArticleObject({
                        ...article.fields,
                        metadata: article.metadata,
                        id: article.sys.id,
                        contentTypeId: article.sys.contentType.sys.id
                    })
                );
            }
        } catch (e) {
            console.error(e);
        }

        return { articles, total };
    }

    /**
     * Gets a list of news articles based on the limit.
     * @param contentType
     * @param limit
     * @param skip
     * @param options
     * @returns {Promise<{articles: Array, total: number}>}
     */
    async getItems(
        contentType = null,
        limit,
        skip = 0,
        options: Record<string, string | number | boolean> = {
            order: "-fields.date"
        } // TS HACK Record
    ) {
        let articles = [];
        let total = 0;

        if (contentType !== null) {
            options = {
                content_type: contentType,
                ...options
            };
        }

        if (skip && skip > 0) {
            options.skip = skip;
        }

        if (limit && limit > 0) {
            options.limit = limit;
        }

        try {
            let response = await this.getPageEntities(options);
            // TS CODE CHANGE
            if (response && response.total) {
                total = response.total;
            }

            if (response && response.items) {
                articles = response.items.map((article) =>
                    ContentfulService.formatArticleObject({
                        ...article.fields,
                        metadata: article.metadata,
                        id: article.sys.id,
                        contentTypeId: article.sys.contentType.sys.id
                    })
                );
            }
        } catch (e) {
            console.error(e);
        }

        return { articles, total };
    }

    /**
     * Get single article.
     * @param entryId
     * @returns {Promise<Entry<any> | void>}
     */
    getSingleArticle(slug) {
        return this.getPageEntitiesBySlug(slug)
            .then((response) => {
                if (response && response.items && response.items.length === 1) {
                    let article = response.items[0];

                    return ContentfulService.formatArticleObject({
                        ...article.fields,
                        id: article.sys.id,
                        contentTypeId: article.sys.contentType.sys.id
                    });
                }

                return null;
            })
            .catch((err) => console.error(err));
    }

    /**
     * Get a single page title.
     * @param {string} pageTitle Page's unique title
     */
    getSinglePage(pageTitle) {
        return this.getPageEntities({
            "fields.title": pageTitle,
            content_type: "page"
        })
            .then((response) => {
                if (response && response.items && response.items.length === 1) {
                    let article = response.items[0];

                    return ContentfulService.formatArticleObject({
                        ...article.fields,
                        id: article.sys.id,
                        contentTypeId: article.sys.contentType.sys.id
                    });
                }

                return null;
            })
            .catch((err) => console.error(err));
    }

    /**
     * Returns a single article based on a filed and given value.
     * @param {} contentType
     * @param {*} fieldName
     * @param {*} fieldValue
     */
    getArticleByField(contentType, fieldName, fieldValue) {
        return this.getArticles(contentType, 1, 0, {
            [fieldName]: fieldValue
        })
            .then((response) => {
                if (
                    response &&
                    response.articles &&
                    response.articles.length === 1
                ) {
                    return response.articles[0];
                }

                return null;
            })
            .catch((e) => {
                console.error(e);
            });
    }

    /**
     * Gets a list of grant articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getCharts(limit = 7) {
        return this.getArticles("chart", limit, 0, {
            "fields.date[lte]": new Date().toISOString(),
            order: "-fields.date"
        }).then(({ articles }) => {
            if (!articles) {
                return [];
            }

            return articles;
        });
    }

    /**
     * Gets a list of grant articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getEventsArticles(limit = 8) {
        return this.getArticles(PAGE_EVENTS, limit, 0, {
            "fields.date[gte]": new Date().toISOString(),
            "fields.isPrivate[ne]": true,
            order: "fields.date"
        }).then(({ articles }) => {
            if (!articles) {
                return [];
            }

            return articles;
        });
    }

    /**
     * Gets a list of grant articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getNewsArticles(limit = 8) {
        return this.getArticles(PAGE_NEWS, limit, 0, {
            "metadata.tags.sys.id[nin]": "sectionExclusiveCCFacility",
            order: "-fields.date"
        }).then(({ articles }) => {
            if (!articles) {
                return [];
            }

            return articles;
        });
    }

    /**
     * Gets a list of grant articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getGrantArticles(limit = 4) {
        return this.getArticles(PAGE_GRANT, limit, 0, {
            order: "-fields.date"
        })
            .then(({ articles }) => {
                if (!articles) {
                    return [];
                }

                return articles;
            })
            .catch((err) => console.error(err));
    }

    /**
     * Gets a list of special initiative articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getSpecialInitiativeArticles(limit = 4, options = {}) {
        return this.getArticles(PAGE_INITIATIVES, limit, 0, {
            order: "-fields.order",
            ...options
        })
            .then(({ articles }) => {
                if (!articles) {
                    return [];
                }

                return articles;
            })
            .catch((err) => console.error(err));
    }

    /**
     * Gets a list of grant articles.
     * @param limit
     * @returns {Promise<Array>}
     */
    getFundingWindows(limit = 10, options = {}) {
        return this.getArticles("designFundingWindow", limit, 0, {
            order: "-fields.order",
            ...options
        })
            .then(({ articles }) => {
                if (!articles) {
                    return [];
                }

                return articles;
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    }

    /**
     * Get page content.
     * @param entryId
     * @returns {Promise<Entry<any> | void>}
     */
    getPageContent(entryId) {
        return this.getSingleArticle(entryId)
            .then((article) => (article.body ? article.body : ""))
            .catch((err) => console.error(err));
    }

    /**
     * Get page entities
     * @param query
     * @returns {Promise<Array<Entry<any>> | void>}
     */
    getPageEntities(query) {
        const url = `${config.contentful_proxy}/getPageEntities`;
        return makeRequest(this.cookiesJSON, {
            url,
            body: query,
            method: "POST"
        }).catch(console.error);
    }

    getPageEntitiesBySlug(slug) {
        const url = `${config.contentful_proxy}/getPageEntitiesBySlug`;
        return makeRequest(this.cookiesJSON, {
            url,
            body: { slug },
            method: "POST"
        }).catch(console.error);
    }
}

let load;
/**
 * Load an instance for quick access.
 * @returns {ContentfulService}
 */
export default function(cookiesJSON?) {
    if (!load) {
        load = new ContentfulService(cookiesJSON);
    }
    return load;
}
