import Cookies from "js-cookie";
import https from "https";

export const makeRequest = async function(
    cookiesJSON,
    { url, body = {}, method = "GET", headers = {} }
): Promise<{ total?: any; items?: any }> {
    if (cookiesJSON == null) {
        cookiesJSON = Cookies.get();
    }

    const httpsAgent = new https.Agent({
        rejectUnauthorized: false
    });

    const bodyString = JSON.stringify(body);

    const responseHeaders: {
        "Content-Type": string;
        Authorization?: string;
    } = {
        ...headers,
        "Content-Type": "application/json"
    };

    const authToken = JSON.parse(cookiesJSON?.convergence_auth || "{}").token;
    if (authToken !== undefined) {
        responseHeaders.Authorization = "Bearer " + authToken;
    }

    const response: Response = await fetch(url, {
        method,
        body: bodyString,
        headers: responseHeaders,
        credentials: "include",
        // @ts-ignore ERROR FOUND fetch is expecting RequestInit
        // agent does not exist in type RequestInit
        agent: httpsAgent
    });

    let json = {};

    if (!response.ok) {
        let message = `Failed api request to ${url}`;
        try {
            const responseText = await response.text();
            if (responseText) {
                message += responseText;
            }
        } catch (e) {
            throw new Error(
                `Api request to ${url}: ${message} ${response.status}`
            );
        }
    }
    try {
        // in case the server returns invalid json
        json = await response.json();
    } catch (e) {
        return {};
    }
    return json;
};
